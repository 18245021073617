var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"1","md":"1"}},[_c('v-btn',{attrs:{"dark":"","block":"","color":"rgb(231,87,83)","to":{
            name: 'AccountList',
          }}},[_vm._v("Back")])],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-iterator',{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.documentInfoId,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-card-subtitle',{staticClass:"subheading font-weight-bold"},[_vm._v(" File Name: "+_vm._s(item.name)+" ")])],1),_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-switch',{staticClass:"mr-2",attrs:{"value":"DOC_APPROVED","inset":""},on:{"change":function($event){return _vm.approveIdentity(item)}},model:{value:(item.statusId),callback:function ($$v) {_vm.$set(item, "statusId", $$v)},expression:"item.statusId"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","fab":"","color":"green","size":"25"},on:{"click":function($event){return _vm.getDocumentData(item)}}},[_c('v-icon',[_vm._v("visibility")])],1)],1)],1),_c('v-divider'),_vm._l((item.documentsList),function(item,i){return _c('v-list',{key:i,attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(item.fileName)+" ")]),_c('v-list-item-content',{staticClass:"align-end"},[(item.mimeTypeId != 'application/pdf')?_c('img',{staticClass:"cursor-pointer",attrs:{"src":("data:image/jpg;base64," + (item.base64)),"alt":"base64","width":"80"},on:{"click":function($event){return _vm.viewDocData(
                              ("data:image/jpg;base64," + (item.base64)),
                              item.fileName,
                              item.mimeTypeId
                            )}}}):_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.viewDocData(
                              ("data:" + (item.mimeTypeId) + ";base64," + (item.base64)),
                              item.fileName,
                              item.mimeTypeId
                            )}}},[_c('v-icon',{attrs:{"size":"100","color":"red"}},[_vm._v("picture_as_pdf")])],1)])],1)],1)})],2)],1)}),1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.viewDoc),callback:function ($$v) {_vm.viewDoc=$$v},expression:"viewDoc"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.fileName))]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.viewDoc = false}}},[_vm._v("cancel")])],1),(_vm.mimeType != 'application/pdf')?_c('img',{attrs:{"src":_vm.docData,"width":"100%","alt":_vm.docData}}):_c('iframe',{attrs:{"src":_vm.docData,"width":"100%","frameborder":"0","height":"700"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.viewUserDocumentList),callback:function ($$v) {_vm.viewUserDocumentList=$$v},expression:"viewUserDocumentList"}},[_c('uploadDocumentsVue',{attrs:{"userDocumentInfo":_vm.userDocumentInfo},on:{"close-user-modal":_vm.closeUserModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }